<template>
  <app-layout>
    <template v-slot:header> Retrageri </template>
    <page-section>
      <vendors-show-tabs current="vendors.withdrawals.index" :id="id" />
    </page-section>
    <page-section>
      <page-section-header>
        Balanta {{ formatPrice(balance) }}
        <template v-slot:actions>
          <vendors-withdrawals-index-button-add />
        </template>
      </page-section-header>
      <v-card>
        <loader v-if="loading" class="my-5" />
        <div v-else-if="totalRows">
          <vendors-withdrawals-index-table
            :rows="rows"
            @update:status="updateVendorWithdrawalStatus"
          />
          <pagination
            @change:page="index($event)"
            :total="meta.total"
            :current_page="meta.current_page"
            :from="meta.from"
            :to="meta.to"
            :next_page_url="links.next"
            :prev_page_url="links.prev"
          />
        </div>
        <no-results v-else class="m-5" />
      </v-card>
    </page-section>
  </app-layout>
</template>

<script>
import { priceFormatter } from "@/helpers";
import vendorsWithdrawalsApi from "@/api/resources/vendorsWithdrawalsApi";
import VCard from "../components/ui/VCard.vue";
import AppLayout from "../layout/AppLayout.vue";
import Pagination from "../components/Pagination.vue";
import VendorsWithdrawalsIndexButtonAdd from "../components/VendorsWithdrawalsIndexButtonAdd.vue";
import VendorsWithdrawalsIndexTable from "../components/VendorsWithdrawalsIndexTable.vue";
import Loader from "../components/Loader.vue";
import NoResults from "../components/NoResults.vue";
import PageSection from "../components/ui/PageSection.vue";
import PageSectionHeader from "../components/ui/PageSectionHeader.vue";
import VendorsShowTabs from '../components/VendorsShowTabs.vue';

export default {
  components: {
    AppLayout,
    VCard,
    Pagination,
    VendorsWithdrawalsIndexButtonAdd,
    VendorsWithdrawalsIndexTable,
    Loader,
    NoResults,
    PageSection,
    PageSectionHeader,
    VendorsShowTabs,
  },
  props: ["id"],

  data() {
    return {
      rows: [],
      meta: {},
      links: {},
      loading: true,
      balance: 0,
    };
  },

  computed: {
    totalRows() {
      return this.rows.length;
    },
  },

  methods: {
    formatPrice(price) {
      return priceFormatter(price);
    },

    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    updateVendorWithdrawalStatus(vendorWithdrawal) {
      this.rows.forEach((row) => {
        if (
          row.id === vendorWithdrawal.id &&
          row.status.id !== vendorWithdrawal.status.id
        ) {
          this.index();
        }
      });
    },

    index(page = null) {
      this.loading = true;

      vendorsWithdrawalsApi
        .index(this.id, page && { page })
        .then((data) => {
          this.rows = data.data;
          this.meta = data.meta;
          this.links = data.links;

          this.scrollTop();
          this.loading = false;
        })
        .catch((error) => console.log(error));
    },

    showBalance() {
      vendorsWithdrawalsApi
        .balance(this.id)
        .then((response) => (this.balance = response.data))
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    this.showBalance();
    this.index();
  },
};
</script>
