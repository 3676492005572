<template>
  <router-link
    class="
      inline-flex
      items-center
      px-4
      py-2
      border border-transparent
      rounded-md
      shadow-sm
      text-sm
      font-medium
      text-white
      bg-yellow-600
      hover:bg-yellow-700
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500
    "
    :to="{ name: 'vendors.withdrawals.create' }"
  >
    <v-button-icon>
      <PlusIcon />
    </v-button-icon>
    Adauga retragere
  </router-link>
</template>

<script>
import { PlusIcon } from "@heroicons/vue/solid";
import VButtonIcon from "./ui/VButtonIcon.vue";

export default {
  components: { PlusIcon, VButtonIcon },
};
</script>